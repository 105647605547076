import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { generateLifestyleImages } from '@utils/images';
import { BASE_FRAME_NAMES, DEFAULT_CATEGORIES, DEFAULT_DEMOS, FRAME_COLORS } from '@constants';

const useLifeStyleImage = ({
	frameColor,
	frame,
	journey,
	enabled,
	count = 1,
}: {
	frameColor: FRAME_COLORS;
	frame: `The ${(typeof BASE_FRAME_NAMES)[number]}`;
	journey: (typeof DEFAULT_CATEGORIES)[number];
	enabled: boolean;
	count?: number;
}) => {
	const { query } = useRouter();
	return useQuery(
		['life-style-images', frameColor, frame],
		async () =>
			await generateLifestyleImages({
				demo: query.demo as (typeof DEFAULT_DEMOS)[number],
				category: journey,
				name: frame,
				count,
				frameColor,
			}),
		{
			enabled,
			retry: 3,
			cacheTime: Infinity,
		}
	);
};

export default useLifeStyleImage;
